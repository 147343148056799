import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "selectAll"];

  connect() {
    this.updateSelectAllState();
  }

  toggleAll(event) {
    const isChecked = event.target.checked;
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
  }

  updateSelectAllState() {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked);
    this.selectAllTarget.checked = allChecked;
  }

  toggleCheckbox() {
    this.updateSelectAllState();
  }
}
